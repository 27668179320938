/* Label */

label {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  margin-bottom: 10px;
  transition: all 0.35s;
}
.label__span {
  color: rgba(46, 50, 66, 1);
  font-family: helvetica;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
  transition: all 0.35s;
}
