.modal {
  background: rgba(1, 1, 1, 0.5);
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  overflow-y: auto;
  padding: 20px;
}
.modal .app-body__content {
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(29, 31, 41, 0.15);
  margin: auto;
  overflow-y: hidden;
}
.modal--sm .app-body__content {
  max-width: 400px;
}
.modal--md .app-body__content {
  max-width: 800px;
}
.modal--lg .app-body__content {
  max-width: 1200px;
}
