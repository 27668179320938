.app-header {
  background: rgba(255, 255, 255, 1);
  border-bottom: 1px solid rgba(213, 218, 237, 1);
  box-sizing: border-box;
  display: flex;
  flex: 0 0 50px;
  line-height: 50px;
  position: relative;
}
.app-header::after {
  content: " ";
  height: 50px;
  pointer-events: none;
  position: absolute;
  box-shadow: 0px 2px 4px 0px rgba(29, 31, 41, 0.15);
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}
.app-header__menu {
  border-right: 1px solid rgba(213, 218, 237, 1);
  box-sizing: border-box;
  margin-left: 0px;
  text-align: center;
  width: 50px;
  cursor: pointer;
  transition: all 0.35s;
}
@media (min-width: 768px) {
  .app-header__menu {
    margin-left: -50px;
  }
}
.app-header__logo {
  align-items: center;
  display: flex;
  font-size: 18px;
  font-weight: 400;
  padding-left: 20px;
}
.app-header__logo img {
  height: 26px;
}
