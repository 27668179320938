/* Dropdown */

select {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(213, 218, 237, 1);
    border-radius: 4px;
    box-shadow: inset 0px 1px 2px 0px rgba(29, 31, 41, 0.15),
      0px 0px 0px 0px rgba(56, 95, 194, 1);
    box-sizing: border-box;
    color: rgba(46, 50, 66, 1);
    font: 400 14px helvetica;
    height: 35px; /* Match the input height */
    line-height: 35px; /* Center the text vertically */
    margin: 0px 0px 10px;
    outline: 0px;
    padding: 0px 10px; /* You might need to adjust this for select */
    transition: all 0.35s;
    width: 100%; /* Full width */
    -webkit-appearance: none; /* Removes default styling on iOS */
    -moz-appearance: none; /* Removes default styling on Firefox */
    appearance: none; /* Standard CSS3 property to remove default arrow */
  }
  
  select:hover {
    border: 1px solid rgba(170, 175, 197, 1);
    transition: all 0.35s;
  }
  
  select:focus {
    border: 1px solid rgba(56, 95, 194, 1);
    box-shadow: inset 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
      0px 0px 0px 1px rgba(56, 95, 194, 1);
    transition: all 0.35s;
  }
  
  select:disabled {
    background: rgba(230, 232, 242, 1);
    border: 1px solid rgba(213, 218, 237, 1);
    box-shadow: 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
      0px 0px 0px 0px rgba(56, 95, 194, 1);
    color: rgba(125, 132, 161, 1);
    cursor: not-allowed;
  }
  
  /* Style for the dropdown arrow */
  select:after {
    content: '▼';
    padding: 0 8px;
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    height: 35px; /* Match the select height */
    line-height: 35px; /* Center the arrow vertically */
    pointer-events: none;
  }
  
  /* This is necessary if you want to add a custom arrow */
  .select-wrapper {
    position: relative;
  }
  
  .select-wrapper select {
    -webkit-appearance: none; /* For iOS */
    -moz-appearance: none; /* For Firefox */
    appearance: none; /* For modern browsers */
  }
  