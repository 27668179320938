/* Button */

.button {
  align-items: center;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 0px rgba(29, 31, 41, 1);
  box-sizing: border-box;
  color: rgba(56, 95, 194, 1);
  display: flex;
  font-family: helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  justify-content: center;
  line-height: 33px;
  margin-bottom: 10px;
  min-width: 100px;
  outline: 0px;
  padding: 0px 10px;
  text-decoration: none;
  transition: all 0.35s;
}
.button:hover,
.button:focus {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(69, 130, 229, 1);
  box-shadow: 0px 1px 2px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 1px rgba(69, 130, 229, 1);
  color: rgba(69, 130, 229, 1);
  cursor: pointer;
  outline: none;
  transition: all 0.35s;
}
.button--icon {
  min-width: 40px;
}
.button--plain {
  border: 1px solid rgba(56, 95, 194, 0);
  background: transparent;
  box-shadow: none;
}
.button--plain:hover,
.button--plain:focus {
  box-shadow: 0px 1px 2px 0px rgba(29, 31, 41, 0.15);
}
.button--danger {
  color: rgba(194, 56, 56, 1);
}
.button--danger:hover,
.button--danger:focus {
  border: 1px solid rgba(229, 69, 69, 1);
  box-shadow: 0px 1px 2px 0px rgba(29, 31, 41, 0.15);
  color: rgba(229, 69, 69, 1);
}
.button:disabled,
.button--disabled,
.button--disabled:hover,
.button--disabled:focus {
  background: rgba(230, 232, 242, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  box-shadow: 0px 0px 0px 0px rgba(29, 31, 41, 0);
  color: rgba(125, 132, 161, 1);
  cursor: not-allowed;
}
.button--small {
  font-family: helvetica;
  font-size: 14px;
  font-weight: 400;
  height: 25px;
  justify-content: center;
  line-height: 23px;
  margin-bottom: 10px;
  min-width: 80px;
  outline: 0px;
  padding: 0px 5px;
}
