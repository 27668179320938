
.checkbox {
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  white-space: nowrap;
  margin-bottom: 10px;
}

.checkbox input {
  cursor: pointer;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
  line-height: initial;
  border-width: 1px;
  border-style: solid;
  outline: 0;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  position: relative;
  /* -webkit-appearance: none; */
  border-color: #d5daed;
  background-color: #ffffff;
  width: 20px;
  height: 20px;
}
.checkbox input:checked {
  border-color: #2c4098;
  background-color: #2c4098;
}
.checkbox input:checked::before {
  color: #ffffff;
}
.checkbox input:checked::before {
  border-radius: 4px;
  transform: scale(1) translate(-50%, -50%);
}
.checkbox input::before {
  color: #656565;
}
.checkbox input::before {
  /* content: "\e118"; */
  width: 12px;
  height: 12px;
  font-size: 12px;
  font-family: "WebComponentsIcons", monospace;
  transform: scale(0) translate(-50%, -50%);
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
}
.checkbox span {
  font-family: helvetica;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  transition: all 0.35s;
}
.checkbox--disabled {
  color: rgba(125, 132, 161, 1);
  cursor: default;
}
.checkbox:not(.checkbox--disabled):hover span,
.checkbox:not(.checkbox--disabled):focus span {
  color: rgba(69, 130, 229, 1);
  transition: all 0.35s;
}
