/* Text Input */

input {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  border-radius: 4px;
  box-shadow: inset 0px 1px 2px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 0px rgba(56, 95, 194, 1);
  box-sizing: border-box;
  color: rgba(46, 50, 66, 1);
  font: 400 14px helvetica;
  line-height: 35px;
  height: 35px;
  margin: 0px 0px 10px;
  outline: 0px;
  padding: 0px 10px;
  transition: all 0.35s;
  width: 100%;
}
input::placeholder {
  color: rgba(125, 132, 161, 0.5);
  transition: all 0.35s;
}
input:hover {
  border: 1px solid rgba(170, 175, 197, 1);
  transition: all 0.35s;
}
input:hover::placeholder {
  color: rgba(125, 132, 161, 1);
  transition: all 0.35s;
}
input:focus {
  border: 1px solid rgba(56, 95, 194, 1);
  box-shadow: inset 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 1px rgba(56, 95, 194, 1);
  transition: all 0.35s;
}
input:disabled {
  background: rgba(230, 232, 242, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  box-shadow: 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 0px rgba(56, 95, 194, 1);
  color: rgba(125, 132, 161, 1);
}
input:disabled::placeholder {
  color: rgba(125, 132, 161, 1);
}
input:disabled:hover,
input:disabled:focus {
  border: 1px solid rgba(213, 218, 237, 1);
  color: rgba(125, 132, 161, 1);
  cursor: not-allowed;
}
input:disabled:hover::placeholder,
input:disabled:focus::placeholder {
  color: rgba(125, 132, 161, 1);
}
