/* Label */

.validation {
  background-color: rgba(213, 218, 237, 0.15);
  border: 1px solid rgba(213, 218, 237, 1);
  border-radius: 4px;
  color: rgba(125, 132, 161, 1);
  margin-bottom: 10px;
  padding: 10px;
}
.validation--success {
  background-color: rgba(69, 229, 69, 0.15);
  border: 1px solid rgba(69, 229, 69, 1);
  color: rgba(56, 194, 56, 1);
}
.validation--success a,
.validation--success .a {
  color: rgba(45, 154, 45, 1);
}
.validation--success a:hover,
.validation--success .a:hover,
.validation--success a:focus,
.validation--success .a:focus {
  color: rgba(69, 229, 69, 1);
}
.validation--danger {
  background-color: rgba(229, 69, 69, 0.15);
  border: 1px solid rgba(229, 69, 69, 1);
  color: rgba(194, 56, 56, 1);
}
.validation--danger a,
.validation--danger .a {
  color: rgba(154, 45, 45, 1);
}
.validation--danger a:hover,
.validation--danger .a:hover,
.validation--danger a:focus,
.validation--danger .a:focus {
  color: rgba(229, 69, 69, 1);
}
.validation--info {
  background-color: rgba(104, 166, 243, 0.15);
  border: 1px solid rgba(104, 166, 243, 1);
  color: rgba(69, 114, 194, 1);
}
.validation--info a,
.validation--info .a {
  color: rgba(52, 93, 162, 1);
}
.validation--info a:hover,
.validation--info .a:hover,
.validation--info a:focus,
.validation--info .a:focus {
  color: rgba(104, 166, 243, 1);
}
.validation--warning {
  background-color: rgba(229, 189, 69, 0.15);
  border: 1px solid rgba(229, 189, 69, 1);
  color: rgba(194, 144, 36, 1);
}
.validation--warning a,
.validation--warning .a {
  color: rgba(151, 112, 28, 1);
}
.validation--warning a:hover,
.validation--warning .a:hover,
.validation--warning a:focus,
.validation--warning .a:focus {
  color: rgba(229, 189, 69, 1);
}
