/* Table */

.table-responsive {
  border: 1px solid rgba(213, 218, 237, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  width: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
  border: 0px solid transparent;
  font-family: helvetica;
  text-align: left;
  width: 100%;
}
th {
  background: linear-gradient(0deg, rgba(213, 218, 237, 1), rgba(230, 232, 242, 1));
  border: 1px solid rgba(213, 218, 237, 1);
  color: rgba(46, 50, 66, 1);
  font-size: 13px;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  padding: 0px 10px;
  white-space: nowrap;
}
td {
  background: rgba(255, 255, 255, 0);
  border: 1px solid rgba(213, 218, 237, 1);
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  padding: 5px 10px;
}

table th {
  border-top: 0px;
}

table td {
  border-bottom: 0px;
}
table th:first-child,
table td:first-child {
  border-left: 0px;
}
table th:last-child,
table td:last-child {
  border-right: 0px;
}

.col-collapse {
  width: 1px;
}
table .button-group .button {
  margin-bottom: 0px;
}
