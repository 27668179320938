@import-normalize body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-title {
  margin: 10px 20px 10px 20px;
  border-top: 1px solid #ddd;
  text-align: center;
  padding: 10px 10px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  font-size: 18px;
  width: calc(100% - 40px);
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.loading-icon {
  display: inline-block;
  margin-bottom: 15px;
  width: 25px;
  height: 25px;
  border: 3px solid rgba(195, 195, 195, 0.6);
  border-radius: 50%;
  border-top-color: #636767;
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ReactVirtualized__Table__rowColumn {
  line-height: normal;
}