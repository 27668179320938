/* Button Group */

.button-group {
  display: inline-flex;
}
.button-group .button {
  margin-right: 10px;
}
.button-group .button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.button-group .button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-right: 0px;
}
.button-group--collapsed .button {
  border-radius: 0px;
  margin-right: 0px;
}
.button-group--sm .button {
  font-size: 13px;
  font-weight: 400;
  height: 25px;
  line-height: 13px;
  min-width: 50px;
  padding: 0px 5px;
}
.button-group--lg .button {
  font-size: 18px;
  font-weight: 300;
  height: 50px;
  min-width: 150px;
  padding: 0px 10px;
}
.button-group--tall .button {
  height: 50px;
}
@media (min-width: 768px) {
  .button-group--tall .button {
    height: 100px;
  }
}
.button-group--flex {
  display: flex;
}
.button-group--flex .button {
  flex: 1 1 auto;
}
