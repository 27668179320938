.app-body__left-nav .left-nav__link {
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-sizing: border-box;
  color: rgba(230, 232, 242, 1);
  display: block;
  font-size: 13px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 10px;
  text-decoration: none;
  transition: all 0.35s;
}
.app-body__left-nav .left-nav__link:hover,
.app-body__left-nav .left-nav__link:focus {
  background: rgba(29, 31, 41, 1);
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-sizing: border-box;
  box-shadow: inset 10px 0px 0px 0px rgba(44, 64, 152, 1);
  color: rgba(255, 255, 255, 1);
  outline: none;
  text-decoration: none;
  transition: all 0.35s;
}
.app-body__left-nav a.active .left-nav__link {
  background: rgba(29, 31, 41, 1);
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-sizing: border-box;
  box-shadow: inset 10px 0px 0px 0px rgba(56, 95, 194, 1);
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
  transition: all 0.35s;
}
.app-body__left-nav a.active .left-nav__link:hover,
.app-body__left-nav a.active .left-nav__link:focus {
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-sizing: border-box;
  box-shadow: inset 10px 0px 0px 0px rgba(56, 95, 194, 1);
  transition: all 0.35s;
}
.app-body__left-nav .left-nav__link--disabled,
.app-body__left-nav .left-nav__link--disabled:hover,
.app-body__left-nav .left-nav__link--disabled:focus {
  background: rgba(63, 66, 79, 1);
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-shadow: inset 0px 0px 0px 0px rgba(44, 64, 152, 1);
  box-sizing: border-box;
  color: rgba(125, 132, 161, 1);
  cursor: not-allowed;
}
