html,
body {
  bottom: 0px;
  color: rgba(46, 50, 66, 1);
  font: 400 14px helvetica;
  left: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}
.app {
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.app--modal {
  z-index: 2;
}

.app-body {
  background: #ccc;
  display: flex;
  flex: 100 100 auto;
  overflow-y: hidden;
}
.app-body__content {
  background: rgba(245, 246, 250, 1);
  display: flex;
  flex: 100 100 auto;
  overflow-y: auto;
  padding: 15px 20px 20px;
}
.app-body__content--gradient {
  background: radial-gradient(rgba(255, 255, 255, 1), rgba(245, 246, 250, 1));
}
.app-body__page {
  display: flex;
  flex-direction: column;
  margin: 0px auto;
  /*max-width: 1200px;*/
  width: 100%;
}
.app-body__page--sm {
  max-width: 400px;
}

/* Typography */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.p {
  color: rgba(46, 50, 66, 1);
  font-family: helvetica;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 10px;
  margin-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  transition: all 0.35s;
}
h1,
.h1 {
  font-size: 30px;
  font-weight: 300;
  line-height: 45px;
}
h2,
.h2 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}
h3,
.h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
h4,
.h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
}
h5,
.h5 {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}
h6,
.h6 {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
}
h1 span,
.h1 span,
h2 span,
.h2 span,
h3 span,
.h3 span,
h4 span,
.h4 span,
h5 span,
.h5 span,
h6 span,
.h6 span {
  font-weight: 400;
  margin-left: 10px;
}
p,
.p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.p--bold {
  font-weight: 700;
}
.p--italic {
  font-style: italic;
}
.p--small {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.p--input {
  min-height: 35px;
}
a,
.a {
  color: rgba(56, 95, 194, 1);
  transition: all 0.35s;
  text-decoration: none;
}
a:hover,
.a:hover,
a:focus,
.a:focus {
  color: rgba(69, 130, 229, 1);
  cursor: pointer;
  transition: all 0.35s;
}
a:disabled,
.a--disabled,
a:disabled:hover,
.a--disabled:hover,
a:disabled:focus,
.a--disabled:focus {
  color: rgba(125, 132, 161, 1);
  cursor: not-allowed;
}
.text-elipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Flex Group */

.flex__parent {
  display: flex;
  flex: 1 1 auto;
  max-width: 100%;
  width: 100%;
}
.flex__parent--col {
  flex-direction: column;
}
.flex__child {
  display: flex;
  flex: 1 1 auto;
  min-width: 0px;
}
.flex__child--notification {
  align-items: center;
  flex: 0 0 auto;
  margin-left: 10px;
}

/* Status Tag */

.status-tag {
  background: rgba(230, 232, 242, 1);
  border-radius: 100px;
  border: 1px solid rgba(213, 218, 237, 1);
  display: inline-flex;
  flex: 1 1 auto;
  font-size: 13px;
  justify-content: center;
  line-height: 13px;
  height: 13px;
  min-width: 13px;
  padding: 5px 10px;
  white-space: nowrap;
}
.status-tag--sm {
  padding: 3px;
}
.status-tag--lg {
  font-size: 20px;
  font-weight: 300;
  height: 20px;
  line-height: 20px;
  min-width: 20px;
  padding: 5px;
}
.status-tag--attention {
  background: rgba(30, 144, 255, 1);
  border: 1px solid rgba(9, 117, 225, 1);
  color: rgba(255, 255, 255, 1);
}

/* Element - Input Groups */

.input-group {
  display: flex;
  flex: 1;
  align-items: flex-end;
}
.input-group input {
  border-radius: 4px 0px 0px 4px;
}
.input-group .button {
  border-radius: 0px;
  flex: 0 0 40px;
  min-width: auto;
}
.input-group .button:last-child {
  border-radius: 0px 4px 4px 0px;
}

/* Divider */

.hr,
.hr--darkest {
  margin-bottom: 10px;
}
.hr {
  border-bottom: 1px solid rgba(230, 232, 242, 1);
}
.hr--darkest {
  border-bottom: 1px solid rgba(213, 218, 237, 1);
}
.hr--headline {
  margin-top: 0px;
}

/* Utility Classes */

.u-block {
  display: block;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.m-top-auto {
  margin-top: auto;
}
.m-top-0 {
  margin-top: 0px;
}
.m-top-10 {
  margin-top: 10px;
}
.m-top-20 {
  margin-top: 20px;
}
.m-top-30 {
  margin-top: 30px;
}
.m-top-40 {
  margin-top: 40px;
}
.m-top-50 {
  margin-top: 50px;
}
.m-top-60 {
  margin-top: 60px;
}
.m-top-70 {
  margin-top: 70px;
}
.m-top-80 {
  margin-top: 80px;
}
.m-top-90 {
  margin-top: 90px;
}
.m-top-100 {
  margin-top: 100px;
}
.m-bot-auto {
  margin-bottom: auto;
}
.m-bot-0 {
  margin-bottom: 0px;
}
.m-bot-10 {
  margin-bottom: 10px;
}
.m-bot-20 {
  margin-bottom: 20px;
}
.m-bot-30 {
  margin-bottom: 30px;
}
.m-bot-40 {
  margin-bottom: 40px;
}
.m-bot-50 {
  margin-bottom: 50px;
}
.m-bot-60 {
  margin-bottom: 60px;
}
.m-bot-70 {
  margin-bottom: 70px;
}
.m-bot-80 {
  margin-bottom: 80px;
}
.m-bot-90 {
  margin-bottom: 90px;
}
.m-bot-100 {
  margin-bottom: 100px;
}

/* Uncomment and set these variables to customize the grid. */

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}

.row {
  box-sizing: border-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  -ms-flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.row.reverse {
  -ms-flex-direction: row-reverse;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -ms-flex-direction: column-reverse;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  box-sizing: border-box;
  -ms-flex: 0 0 auto;
  -webkit-box-flex: 0;
  flex: 0 0 auto;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs {
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  -webkit-box-flex: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 8.333%;
  flex-basis: 8.333%;
  max-width: 8.333%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 16.667%;
  flex-basis: 16.667%;
  max-width: 16.667%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 33.333%;
  flex-basis: 33.333%;
  max-width: 33.333%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 41.667%;
  flex-basis: 41.667%;
  max-width: 41.667%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 58.333%;
  flex-basis: 58.333%;
  max-width: 58.333%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 66.667%;
  flex-basis: 66.667%;
  max-width: 66.667%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 83.333%;
  flex-basis: 83.333%;
  max-width: 83.333%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 91.667%;
  flex-basis: 91.667%;
  max-width: 91.667%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}

.col-xs-offset-1 {
  margin-left: 8.333%;
}

.col-xs-offset-2 {
  margin-left: 16.667%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-4 {
  margin-left: 33.333%;
}

.col-xs-offset-5 {
  margin-left: 41.667%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-7 {
  margin-left: 58.333%;
}

.col-xs-offset-8 {
  margin-left: 66.667%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-10 {
  margin-left: 83.333%;
}

.col-xs-offset-11 {
  margin-left: 91.667%;
}

.start-xs {
  -ms-flex-pack: start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -ms-flex-pack: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -ms-flex-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -ms-flex-align: start;
  -webkit-box-align: start;
  align-items: flex-start;
}

.middle-xs {
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
}

.bottom-xs {
  -ms-flex-align: end;
  -webkit-box-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -ms-flex-order: -1;
  -webkit-box-ordinal-group: 0;
  order: -1;
}

.last-xs {
  -ms-flex-order: 1;
  -webkit-box-ordinal-group: 2;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 46rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-sm {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-1 {
    margin-left: 8.333%;
  }

  .col-sm-offset-2 {
    margin-left: 16.667%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-4 {
    margin-left: 33.333%;
  }

  .col-sm-offset-5 {
    margin-left: 41.667%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-7 {
    margin-left: 58.333%;
  }

  .col-sm-offset-8 {
    margin-left: 66.667%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-10 {
    margin-left: 83.333%;
  }

  .col-sm-offset-11 {
    margin-left: 91.667%;
  }

  .start-sm {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-sm {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-sm {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 62em) {
  .container {
    width: 610px;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-md {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-md-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-md-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-md-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-md-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-md-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-md-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-md-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-md-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-md-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-1 {
    margin-left: 8.333%;
  }

  .col-md-offset-2 {
    margin-left: 16.667%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-4 {
    margin-left: 33.333%;
  }

  .col-md-offset-5 {
    margin-left: 41.667%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-7 {
    margin-left: 58.333%;
  }

  .col-md-offset-8 {
    margin-left: 66.667%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-10 {
    margin-left: 83.333%;
  }

  .col-md-offset-11 {
    margin-left: 91.667%;
  }

  .start-md {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-md {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-md {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 710px;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    box-sizing: border-box;
    -ms-flex: 0 0 auto;
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  .col-lg {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 8.333%;
    flex-basis: 8.333%;
    max-width: 8.333%;
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 16.667%;
    flex-basis: 16.667%;
    max-width: 16.667%;
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 33.333%;
    flex-basis: 33.333%;
    max-width: 33.333%;
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 41.667%;
    flex-basis: 41.667%;
    max-width: 41.667%;
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 58.333%;
    flex-basis: 58.333%;
    max-width: 58.333%;
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 66.667%;
    flex-basis: 66.667%;
    max-width: 66.667%;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 83.333%;
    flex-basis: 83.333%;
    max-width: 83.333%;
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 91.667%;
    flex-basis: 91.667%;
    max-width: 91.667%;
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-1 {
    margin-left: 8.333%;
  }

  .col-lg-offset-2 {
    margin-left: 16.667%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-4 {
    margin-left: 33.333%;
  }

  .col-lg-offset-5 {
    margin-left: 41.667%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-7 {
    margin-left: 58.333%;
  }

  .col-lg-offset-8 {
    margin-left: 66.667%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-10 {
    margin-left: 83.333%;
  }

  .col-lg-offset-11 {
    margin-left: 91.667%;
  }

  .start-lg {
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -ms-flex-pack: center;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -ms-flex-pack: end;
    -webkit-box-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -ms-flex-align: start;
    -webkit-box-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
  }

  .bottom-lg {
    -ms-flex-align: end;
    -webkit-box-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -ms-flex-pack: justify;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -ms-flex-order: -1;
    -webkit-box-ordinal-group: 0;
    order: -1;
  }

  .last-lg {
    -ms-flex-order: 1;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
}

.textarea--output {
  border-radius: 4px;
  box-sizing: border-box;
  font: 400 14px helvetica;
  line-height: 19px;
  margin: 0px 0px 10px;
  height: 200px;
  min-height: 200px;
  outline: 0px;
  padding: 7px 10px;
  transition: all 0.35s;
  width: 100%;
  overflow-y: scroll;
  background: rgba(230, 232, 242, 1);
  border: 1px solid rgba(213, 218, 237, 1);
  box-shadow: 0px 0px 0px 0px rgba(29, 31, 41, 0.15),
    0px 0px 0px 0px rgba(56, 95, 194, 1);
  color: rgba(125, 132, 161, 1);
}

.virtualized .ReactVirtualized__Table__rowColumn {
  padding: 2px;
}
