/* Checkbox Groups */

.checkbox-group {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .checkbox-group--row {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .checkbox-group--row .checkbox,
  .checkbox-group--row .radio {
    margin-right: 20px;
    flex-grow: 0;
  }
}
