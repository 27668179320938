/* Left-nav */

.app-body__left-nav {
  background: rgba(46, 50, 56, 1);
  display: flex;
  flex: 0 0 220px;
  flex-direction: column;
  margin-left: -220px;
  overflow-y: auto;
  transition: all 0.35s;
}
@media (min-width: 768px) {
  .app-body__left-nav {
    margin-left: 0px;
    transition: all 0.35s;
  }
}
.app-body__left-nav .link-header {
  background: linear-gradient(
    0deg,
    rgba(245, 246, 250, 1),
    rgba(213, 218, 237, 1)
  );
  border-bottom: 1px solid rgba(63, 66, 79, 1);
  box-sizing: border-box;
  color: rgba(46, 50, 56, 1);
  display: block;
  font-size: 11px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  padding-left: 20px;
  padding-right: 10px;
  text-decoration: none;
  text-transform: uppercase;
}

/* Login Toggle */

.login-toggle {
  background: rgba(56, 95, 194, 1);
  border: 1px solid rgba(56, 95, 194, 1);
  box-sizing: border-box;
  color: rgba(245, 246, 250, 1);
  min-height: 70px;
  justify-content: center;
  padding-left: 20px;
  padding-right: 5px;
  position: relative;
  text-align: left;
  transition: all 0.35s;
  width: 100%;
}
.login-toggle__gradient::before {
  background: linear-gradient(
    45deg,
    rgba(44, 64, 152, 1) 0%,
    rgba(56, 95, 194, 1) 50%,
    rgba(44, 64, 152, 1) 51%,
    rgba(44, 64, 152, 1) 100%
  );
  content: "";
  display: block;
  position: absolute;
  transition: all 0.35s;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.login-toggle__creds {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.creds--client-name,
.creds--company-name {
  display: block;
  position: relative;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.creds--client-name {
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
}
.creds--company-name {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}

.login-toggle:hover,
.login-toggle:focus {
  background: rgba(56, 95, 194, 1);
  border: 1px solid rgba(56, 95, 194, 1);
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  outline: none;
  transition: all 0.35s;
}
.login-toggle:hover .login-toggle__gradient::before,
.login-toggle:focus .login-toggle__gradient::before {
  opacity: 0.25;
}

.app-body__left-nav .version-info {
  color: rgba(255, 255, 255, 0.20);
  padding: 10px;
  font-size: 0.8em;
}

.app-body__left-nav .spacer {
  flex-grow: 1;
}